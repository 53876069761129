import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { registerCard } from '../../services/mangopay';
import callApi from '../../services/api';
import withOrderContext from '../../withOrderContext';
import withCustomerContext from '../../withCustomerContext';
import { pushToLayer } from '../../services/googleTagManager';

import { mobileThresholdPixels, Button as ButtonV3 } from '../home/v3/styledComponents';
import CreditCard from './CreditCard';
import CreditCardsSelector from './CreditCardsSelector';
import Dots from '../home/v3/Dots';

import logoMangoPay from '../../assets/Mangopay2.png';
import logoAxa from '../../assets/axa.png';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16.5px;
  width: 100%;
  @media (max-width: ${mobileThresholdPixels}) {
    margin-bottom: 20px;
  }
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  ${props => props.column && 'flex-direction: column; margin-top: 70.5px;'}
  ${props => props.marginBottom && `margin-bottom: ${props.marginBottom}px;`}
  font-family: Libre Baskerville;
  font-size: 24px;
  line-height: 30px;
  font-weight: 500;
  text-align: center;
  @media (max-width: ${mobileThresholdPixels}) {
    align-items: flex-start;
    ${props => props.column && 'margin-top: 30px; align-items: center;'}
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 30px;
  }
`;

const GuaranteesContainer = styled.div`
  display: flex;
  font-size: 14px;
  margin-top: 15px;
  align-items: baseline;
  @media (max-width: ${mobileThresholdPixels}) {
    margin-top: 0px;
    margin-bottom: 15px;
    align-items: flex-start;
  }
`;

const P = styled.p`
  font-size: 18px;
  line-height: 28px;
  margin: 0px 0px 23px;
  ${props => props.bold && 'font-weight: 500;'}
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 14px;
    line-height: 20px;
    margin: 0px 0px 10px;
  }
`;

const SubTitle = styled.div`
  font-family: Libre Baskerville;
  font-size: 18px;
  line-height: 28px;
  font-weight: 500;
  margin-bottom: 20px;
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 10px;
  }
`;

const Logo = styled.img`
  height: 17px;
  width: auto;
  margin-right: ${props => props.marginRight}px;
  @media (max-width: ${mobileThresholdPixels}) {
    margin-right: ${props => props.marginRight - 6}px;
  }
`;

const Button = styled(ButtonV3)`
  width: 245px;
  padding: 3px 15px 0px;
  margin-top: 59px;
  font-size: 18px;
  ${props => props.marginRight && 'margin-right: 45px;'}
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 13px;
    width: 187px;
    min-width: unset;
    margin: 25px auto 0px;
    ${props => props.mobileSmallMarginTop && 'margin-top: 10px;'}
  }
`;

const CreditCardInfosContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  @media (max-width: ${mobileThresholdPixels}) {
    flex-direction: column;
  }
`;

const CreditCardForm = styled.div`
  display: flex;
  flex-direction: column;
`;

const scrollToTop = () => {
  if (typeof window === 'undefined') return;
  window.scrollTo(0, 0);
};

class CreditCardInfos extends React.Component {
  constructor(props) {
    super(props);
    const { customerContext: { customer } } = this.props;
    this.state = {
      customerHasCards: customer && customer.cards && customer.cards.length > 0,
      cardName: '',
      cardNumber: '',
      cvv: '',
      selectedCardIndex: 0,
      isAddNewCardSelected: false,
    };
    this.submitCard = this.submitCard.bind(this);
    this.setCreditCardInputValue = this.setCreditCardInputValue.bind(this);
    this.selectCard = this.selectCard.bind(this);
    this.addNewCard = this.addNewCard.bind(this);
  }

  setCreditCardInputValue(input, value) {
    this.setState({ [input]: value });
  }

  selectCard(selectedCardIndex) {
    this.setState({ selectedCardIndex });
  }

  addNewCard() {
    this.setState({ isAddNewCardSelected: true });
  }

  submitCard(isNew) {
    const {
      orderContext: { orderId },
      customerContext: { customer },
    } = this.props;
    if (isNew) {
      const { expiryMonth, expiryYear, cvv, cardNumber, cardId } = this.state;
      const expiryDate = `${expiryMonth}${expiryYear}`;
      const cardData = { expiryDate, cvv, cardNumber, cardId };
      return registerCard(cardData, customer._id)
        .then(card => callApi('public/submitCard', 'post', { card, orderId }))
        .then(() => {
          this.setState({ isCreditCardRegistered: true }, () => scrollToTop());
          pushToLayer({
            event: 'Card Saved',
            context: 'post_order',
          });
        });
    }
    const { selectedCardIndex } = this.state;
    return callApi('public/submitCard', 'post', { card: { CardId: customer.cards[selectedCardIndex].id }, orderId })
      .then(() => {
        this.setState({ isCreditCardRegistered: true }, () => scrollToTop());
      });
  }

  renderCreditCardForm() {
    const { cardName, cardNumber, cvv } = this.state;
    return (
      <CreditCardForm>
        <CreditCard
          onChange={this.setCreditCardInputValue}
          cardName={cardName}
          cardNumber={cardNumber}
          cvv={cvv}
        />
        <GuaranteesContainer alignItems="center" row marginBottom="0">
          <Logo marginRight={12} src={logoMangoPay} alt="Logo MangoPay" />
          Règlement 100% sécurisé via MangoPay
        </GuaranteesContainer>
        <GuaranteesContainer marginLeft justifyContent="left" row marginsMobile alignItems="center">
          <Logo marginRight={15} src={logoAxa} alt="Logo Axa" />
          Vos vêtements sont garantis par notre partenaire AXA
        </GuaranteesContainer>
        <Button navy onClick={() => this.submitCard(true)}>Enregistrer ma carte</Button>
      </CreditCardForm>
    );
  }

  renderCreditCardFormContainer() {
    return (
      <CreditCardInfosContainer>
        <SubTitle>Caution bancaire</SubTitle>
        <P>
          Vous pouvez dès maintenant renseigner vos informations bancaires pour bloquer votre créneau.
          Vous ne serez pas prélevé avant le rendez-vous avec votre couturier.
        </P>
        {this.renderCreditCardForm()}
      </CreditCardInfosContainer>
    );
  }

  render() {
    const {
      isCreditCardRegistered, selectedCardIndex, isAddNewCardSelected, customerHasCards,
    } = this.state;
    const { customerContext: { customer } } = this.props;
    return (
      <Container>
        <Header column marginBottom={31}>
          {!customerHasCards && 'Renseignez votre carte dès maintenant'}
          {customerHasCards && 'Quelle carte souhaitez-vous utiliser pour cette commande ?'}
          <Dots marginTop={5} noMargin />
        </Header>

        <div>
          {!isCreditCardRegistered && (!customer || !customer.cards || customer.cards.length === 0) &&
            this.renderCreditCardFormContainer()}

          {!isCreditCardRegistered && customer && customer.cards && customer.cards.length > 0 && (
            <CreditCardsSelector
              cards={customer.cards}
              selectedCardIndex={selectedCardIndex}
              isAddNewCardSelected={isAddNewCardSelected}
              selectCard={index => this.selectCard(index)}
            />
          )}

          {!isCreditCardRegistered && customer && customer.cards
            && customer.cards.length > 0 && !isAddNewCardSelected &&
              <ButtonsContainer>
                <Button navy marginRight onClick={() => this.submitCard(false)}>Choisir cette carte</Button>
                <Button transparent mobileSmallMarginTop onClick={this.addNewCard}>Ajouter une carte</Button>
              </ButtonsContainer>
          }
          {isAddNewCardSelected && !isCreditCardRegistered && this.renderCreditCardForm()}
          {isCreditCardRegistered &&
            <CreditCardInfosContainer>
              <P bold>
                Votre carte bancaire a bien été renseignée.
                Vous ne serez pas prélevé avant le rendez-vous avec votre couturier.
              </P>
            </CreditCardInfosContainer>
          }
        </div>
      </Container>
    );
  }
}

CreditCardInfos.propTypes = {
  orderContext: PropTypes.shape({
    orderId: PropTypes.string,
  }).isRequired,
  customerContext: PropTypes.shape({
    customer: PropTypes.shape({}),
  }).isRequired,
};

export default withCustomerContext(withOrderContext(CreditCardInfos));
